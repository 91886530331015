import { Injectable, inject } from '@angular/core';
import { ItemCarantyHostCRM, ListEstado, ShowRoomStateAll } from '@core/models/responsehttp.model';
import { AlertService } from '@core/services/alert.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, combineLatest, firstValueFrom } from 'rxjs';
import { map, catchError, tap, concatMap, switchMap } from 'rxjs/operators';
import { ProspectosService } from 'src/app/clientes/prospectos/services/prospectos.service';
import { GlobalApiActions } from '.';
import { GraphService } from '@core/services/graph.service';
import { MenuItem, UserSession } from '@core/models/user-roles.model';
import { ProfileType } from '@core/models/utils.model';
import { CatalogosGeneralesService } from '@core/services/catalogos-generales.service';
import { RolesService } from '@core/services/roles.service';
import { deleteDuplicateArrayObjects } from '@shared/utils/utils';
import { AutosService } from 'src/app/autos/services/autos.service';
import { CarFirebaseObject } from '@core/models/car-firebase.model';
import { StoreLocalStorageAuthService } from '@auth/store-localStorage-auth.service';
import { InspecionesService } from 'src/app/pagos-inspeccion/services/inspeciones.service';
import { TallerInspeccionObject } from 'src/app/pagos-inspeccion/models/pagos-inspeccion.model';

@Injectable()
export class StateGlobalEffects {
  private readonly _prospectosService = inject(ProspectosService);
  private readonly _alertService = inject(AlertService);
  private readonly _catalogosGeneralesService = inject(CatalogosGeneralesService);

  private readonly _graphService = inject(GraphService);
  private readonly _rolService = inject(RolesService);
  private readonly _autosService = inject(AutosService);
  private readonly _storeAuthLocalStorage = inject(StoreLocalStorageAuthService);
  private readonly _inspecionesService = inject(InspecionesService);
  private readonly actions$ = inject(Actions);

  GET_RESOURCES_CRM$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initLoadResorces),
      concatMap(() =>
        this._catalogosGeneralesService.getResourcesCRM().pipe(
          map((response) => {
            let resourcesCRM: any;

            if (response.code === 200 && response.data.length > 0) resourcesCRM = response.data[0];

            return GlobalApiActions.loadSuccessloadResorcesCRM({ resourcesCRM });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_SHOWROOMS_ALL$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initCatalogueShowroomAll, GlobalApiActions.initCatalogueContratos),
      concatMap(() =>
        this._catalogosGeneralesService.getShowRoomsArray().pipe(
          map((response) => {
            if (response.code === 200 && Array.isArray(response.result) && response.result.length > 0)
              return GlobalApiActions.loadSuccessCatalogueShowroomAll({
                showroomsAll: response.result as ShowRoomStateAll[],
              });

            return GlobalApiActions.loadErrorCatalogueShowroomAll({
              errorMessage: 'Error al cargar catálogo de showrooms',
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_ESTADO_CLAVE$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initCatalogueState, GlobalApiActions.initCatalogueContratos),
      concatMap(() =>
        this._prospectosService.getEstadoClave().pipe(
          map((response) => {
            let state: ListEstado[];
            if (response.code === 200 && Array.isArray(response.result)) {
              state = response.result as ListEstado[];
            }
            return GlobalApiActions.loadSuccessCatalogueState({ state });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_CARANTYHOST_CRM$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initCatalogueCHostCRM, GlobalApiActions.initCatalogueContratos),
      concatMap(() =>
        this._catalogosGeneralesService.getListadoUsuariosCRM().pipe(
          map((response) => {
            let carantyhostCRM: ItemCarantyHostCRM[];
            if (response.code === 200 && Array.isArray(response.response)) {
              carantyhostCRM = response.response as ItemCarantyHostCRM[];
            }
            return GlobalApiActions.loadSuccessCatalogueCHostCRM({ carantyhostCRM });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_BANKS$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initCatalogueBanks, GlobalApiActions.initCatalogueContratos),
      concatMap(() =>
        this._catalogosGeneralesService.getDataBanks().pipe(
          map((response) => {
            let banks: string[];
            if (response.code === 200) {
              banks = response.result as string[];
            }
            return GlobalApiActions.loadSuccessCatalogueBanks({ banks });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_USER_PROFILE$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initUserSession),
      concatMap(() =>
        combineLatest([this._graphService.getMeProfile(), this._graphService.getMyGroups()]).pipe(
          map(([profile, groups]) => {
            const userProfile: UserSession = {};

            if (profile) {
              userProfile.profileGraph = profile as ProfileType;

              //usersNotificaMCSolicitudContrato.hostEmail = [profile.userPrincipalName];
              //if (profile.mobilePhone) usersNotificaMCSolicitudContrato.hostNumber = [`${profile.mobilePhone}`];

              userProfile.profileToken = {
                uniqueId: profile.id,
                username: profile.userPrincipalName,
                name: profile.displayName,
                environment: 'login.app.web',
              };
            }

            if (Array.isArray(groups.value) && groups.value.length > 0)
              userProfile.groupsGraph = groups.value.map((group) => group.displayName);

            return {
              userProfile,
            };
          }),
          switchMap(async ({ userProfile }) => {
            const grupos = userProfile.groupsGraph ?? [];
            //const grupos = ['Usuario - CRM', 'Caranty'];

            const scopes = await firstValueFrom(this._rolService.getByRolUserCombineGraph(grupos));

            return {
              userProfile,
              scopes,
            };
          }),

          map(({ userProfile, scopes }) => {
            if (scopes.code === 200 && Array.isArray(scopes.result) && scopes.result.length > 0) {
              scopes.result.sort(function (a, b) {
                if (a.orden > b.orden) {
                  return 1;
                }
                if (a.orden < b.orden) {
                  return -1;
                }
                // a must be equal to b
                return 0;
              });

              userProfile.menuGraph = deleteDuplicateArrayObjects(scopes.result) as MenuItem[];

              userProfile.scopesGraph = userProfile.menuGraph.map((rol: MenuItem) => rol.routerLink) as string[];

              userProfile.menuGraph = userProfile.menuGraph.filter((rol: MenuItem) => rol.visibleMenu);

              this._storeAuthLocalStorage.setScopesUser(userProfile.scopesGraph);
            }

            return GlobalApiActions.loadSuccessUserSession({
              userProfile,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_SHOWROOMS_FILTER$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initListChostAllCompany),
      switchMap((payload) =>
        combineLatest([
          this._graphService.getAllUsersCompanyPagination(),
          this._catalogosGeneralesService.getCarantyHostQuienPuedeVerAquien(payload.email, 'crm'),
          this._graphService.getMembersGroups('11a2a698-2856-4b95-b1c3-4d4523f81cdf'), //Host que hacen contratos
          this._graphService.getDirectReportMe(), //Host en el caso que sea gerente
        ]).pipe(
          map(([hostAll, hostFilter, cHost, directReportsRes]) => {
            let listChost: ProfileType[] = [];
            let listCHostAquienPuedeVer: ProfileType[] = [];
            let listCarantyHostsGraph: ProfileType[] = [];
            let listChostMiEquipo: ProfileType[] = [];

            if (hostAll.length > 0) {
              listChost = hostAll;

              if (hostFilter.code === 200 && Array.isArray(hostFilter.result) && hostFilter.result.length > 0) {
                listCHostAquienPuedeVer = listChost.filter(
                  (item: ProfileType) =>
                    Array.isArray(hostFilter.result) && hostFilter.result.includes(item.userPrincipalName)
                );
              }
            }

            if (Array.isArray(cHost.value) && cHost.value.length > 0) listCarantyHostsGraph = cHost.value;

            if (directReportsRes.value.length > 0) listChostMiEquipo = directReportsRes.value;

            return GlobalApiActions.loadSuccessListChostAllCompany({
              listChost,
              listCHostAquienPuedeVer,
              listCarantyHostsGraph,
              listChostMiEquipo,
            });
          }),

          catchError((err) => {
            console.log(err);

            return EMPTY;
          })
        )
      )
    );
  });

  GET_CARS_PUBLISH$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initGetCarsPublish),
      concatMap(() =>
        this._autosService.getPublicacionesByPage(1).pipe(
          map((response: any) => {
            const cars: CarFirebaseObject[] = [];

            if (response.data.publicaciones.length > 0) cars.push(...response.data.publicaciones);

            return GlobalApiActions.loadSuccessGetCarsPublish({ cars });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  GET_TALLERES_INSPECCION$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalApiActions.initLoadTallerInspeccion),
      concatMap(() =>
        this._inspecionesService.getTalleres().pipe(
          map((response) => {
            if (response.code === 200 && Array.isArray(response.result) && response.result.length > 0) {
              return GlobalApiActions.loadSuccessloadTallerInspeccion({
                talleresInspeccion: response.result as TallerInspeccionObject[],
              });
            }
            return GlobalApiActions.loadErrorCatalogueShowroomAll({
              errorMessage: 'Error al cargar talleres de inspección',
            });
          }),
          catchError(() => EMPTY)
        )
      )
    );
  });

  notifyApiError$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          GlobalApiActions.loadErrorCatalogueCHostCRM,
          GlobalApiActions.loadErrorCatalogueState,
          GlobalApiActions.loadErrorCatalogueBanks
        ),
        tap((action) => this._alertService.error(action.errorMessage))
      );
    },
    { dispatch: false }
  );
}
