import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';

export const selectStateGlobalFeature = (state: AppState) => state.globalState;
export const selectListBanks = createSelector(selectStateGlobalFeature, (state) => state.banks);
export const selectLoadedBanks = createSelector(selectStateGlobalFeature, (state) => state.loadedBanks);

export const selectListCarantyHostCRM = createSelector(selectStateGlobalFeature, (state) => state.carantyhostCRM);
export const selectLoadedCarantyHostCRM = createSelector(
  selectStateGlobalFeature,
  (state) => state.loadedCarantyhostCRM
);

export const selectListState = createSelector(selectStateGlobalFeature, (state) => state.state);
export const selectLoadedState = createSelector(selectStateGlobalFeature, (state) => state.loadedState);

export const selectListShowroomsAll = createSelector(selectStateGlobalFeature, (state) => state.showroomsAll);

export const selectLoadedListContratos = createSelector(
  selectStateGlobalFeature,
  (state) =>
    state.loadedState &&
    state.loadedBanks &&
    state.loadedCarantyhostCRM &&
    state.showroomsAll &&
    state.listCarantyHostsGraph.length > 0
);

export const selectUserSession = createSelector(selectStateGlobalFeature, (state) => state.userSession);

export const selectUserScopes = createSelector(selectStateGlobalFeature, (state) => state.userSession.scopesGraph);

export const selectListCHostAquienPuedeVer = createSelector(
  selectStateGlobalFeature,
  (state) => state.listCHostAquienPuedeVer
);
export const selectListAllCHostCompany = createSelector(selectStateGlobalFeature, (state) => state.listAllCHostCompany);

export const selectListCarantyHostsGraph = createSelector(
  selectStateGlobalFeature,
  (state) => state.listCarantyHostsGraph
);

export const selectListCarantyHostsGraphMiEquipo = createSelector(
  selectStateGlobalFeature,
  (state) => state.listChostMiEquipo
);

export const selectResourcesCRM = createSelector(selectStateGlobalFeature, (state) => state.resourcesCRM);

export const selectCarsPublished = createSelector(selectStateGlobalFeature, (state) => state.carsPublish);

export const selectTalleresInspeccion = createSelector(selectStateGlobalFeature, (state) => state.talleresInspeccion);
